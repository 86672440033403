import React, { useEffect, useRef, useState } from 'react'
import Requests from '../scripts/requests'
import { useSelector } from 'react-redux'
import { Pokemon } from '../scripts/types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faChevronDown, faChevronUp, faClose, faRemove, faSkull, faSortDown, faSortUp, faToggleOff, faToggleOn, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import PokemonTypeBadge from '../components/PokemonTypeBadge'
import CircleLoading from '../components/CircleLoading'
import _ from 'lodash'
import "../css/BoxPokemon.scss"
import { AdminRequests } from '../scripts/admin_requests'
import PokeCircleLoading from '../components/PokeCircleLoading'


function BoxPokemon() {

    const accessToken = useSelector((state: any) => state.user.accessToken)
    const userData = useSelector((state: any) => state.user.userData)

    const [allPokemon, setAllPokemon] = useState<Array<Pokemon>>([])
    const [myPokemon, setMyPokemon] = useState<Array<Pokemon>>([])
    const [pokemonTeam, setPokemonTeam] = useState<Array<Pokemon | null>>([null, null, null])
    const [lineupEnabled, setLineupEnabled] = useState<boolean>(false)
    const [gameStatus, setGameStatus] = useState<boolean>(false)
    const [teamLoaded, setTeamLoaded] = useState(false)
    const [pokemonLoading, setPokemonLoading] = useState(true)

    const [inSearch, setInSearch] = useState(false)
    const [sortStatus, setSortStatus] = useState<string>("none")
    const searchInput = useRef<any>()

    //const [adding, setAdding] = useState<boolean>(false)
    //const [removing, setRemoving] = useState<boolean>(false)
    const [clickedSlot, setClickedSlot] = useState<boolean[]>([false, false, false])
    const [droppedSlot, setDroppedSlot] = useState<boolean[]>([false, false, false])


    useEffect(() => {
        if (accessToken) {
            Requests.getUserPokemonTeam(accessToken)
                .then(res => {
                    setPokemonTeam(res)
                })
                .catch(err => null)
                .finally(() => setTeamLoaded(true))
        }
    }, [accessToken])

    useEffect(() => {

        const addInitialIndex = (list: Pokemon[]) => {
            return list.map((item, index) => ({ ...item, initialIndex: index }));
        };

        if (accessToken) {
            Requests.getUserPokemon(accessToken)
                .then(res => {
                    setMyPokemon(addInitialIndex(res))
                    setAllPokemon(addInitialIndex(res))
                })
                .catch(err => null)
                .finally(() => setPokemonLoading(false))
        }
    }, [accessToken])

    useEffect(() => {
        let interval: NodeJS.Timeout | undefined;
        async function getStatus() {
            Requests.getFantaPokemonStatus(accessToken)
                .then(res => {
                    if (res['lineup']) {
                        setLineupEnabled(true)
                    }

                    if (res['gameStatus']) {
                        setGameStatus(true)
                    }
                })
                .catch(err => null)
        }
        if (accessToken) {
            getStatus()
                .finally(() => {
                    interval = setInterval(() => {
                        getStatus()
                    }, 10 * 1000)
                })
        }

        return (() => {
            if (interval)
                clearInterval(interval)
        })
    }, [accessToken])


    const removePokemon = (pokemonID: number, slotIdx: number = -1) => {
        let slot = slotIdx
        if (slotIdx === -1) {
            slot = pokemonTeam.findIndex((e: Pokemon | null, idx: number) => e ? e.id === pokemonID : false)
        }
        setClickedSlot(prev => prev.map((e, idx) => idx === slot ? true : prev[idx]))

        Requests.removePokemonFromTeam(pokemonID, accessToken)
            .then(res => {
                Requests.getUserPokemonTeam(accessToken)
                    .then(res => {
                        setPokemonTeam(res)
                    })
                    .finally(() => setClickedSlot(prev => prev.map((e, idx) => idx === slot ? false : prev[idx])))
            })
            .catch(err => console.log(err))
        //.finally(() => setClickedSlot(prev => prev.filter(e => e !== slotIdx)))
    }

    // FIXME: migliorare attivazione/disattivazione loading
    const addPokemonInTeam = (pokemonID: number, slot: number = -1) => {
        if (slot === -1) {
            let slotIdx = pokemonTeam.findIndex((e, index) => e === null && !droppedSlot[index]);
            setDroppedSlot(prev => prev.map((e, idx) => idx === slotIdx ? true : prev[idx]))

            Requests.addPokemonToTeam(pokemonID, accessToken)
                .then(res => {
                    Requests.getUserPokemonTeam(accessToken)
                        .then(res => {
                            setPokemonTeam(res)
                        })
                        .finally(() => {
                            setDroppedSlot(prev => prev.map((e, idx) => idx === slotIdx ? false : prev[idx]))
                        })
                })
                .catch(err => console.log(err))
                .finally(() => {
                    setDroppedSlot(prev => prev.map((e, idx) => idx === slotIdx ? false : prev[idx]))
                })
        } else {
            Requests.addPokemonToTeamSlot(pokemonID, slot, accessToken)
                .then(res => {
                    Requests.getUserPokemonTeam(accessToken)
                        .then(res => {
                            setPokemonTeam(res)
                        })
                        .finally(() => {
                            setDroppedSlot(prev => prev.map((e, idx) => idx === slot ? false : prev[idx]))
                        })
                })
                .catch(err => console.log(err))
                .finally(() => {
                    setDroppedSlot(prev => prev.map((e, idx) => idx === slot ? false : prev[idx]))
                })
        }

    }

    const searchPokemon = (ev: any) => {
        let searched = allPokemon.filter((e: Pokemon) => {
            return e.name.toLowerCase().includes(ev.target.value.toLowerCase())
        })

        setMyPokemon(searched)
        setInSearch(true)
    }

    const pokemonTypes = (type: string) => {
        return type.split(",")
    }

    const updateGameStatus = (lineupStatus: boolean, gameStatus: boolean) => {
        AdminRequests.setFantaPokemonStatus(accessToken, {
            "lineup": lineupStatus,
            "gameStatus": gameStatus
        })
            .then(res => {
                setLineupEnabled(res['lineup'])
                setGameStatus(res['gameStatus'])

                if (res["lineup"]) {
                    window.location.reload()
                }
            })
            .catch(err => null)
    }

    /*
    const resetGame = () => {
        let confirm = window.confirm("Sicuro di voler cancellare tutti i dati pokemon?")
        if (confirm && accessToken) {
            AdminRequests.resetFantaPokemon(accessToken)
                .then(res => {
                    window.location.reload()
                })
                .catch(err => null)
        }
    }*/

    const pokemonIsInTeam = (pokemon: Pokemon) => {
        return pokemonTeam.some((e: Pokemon | null) => {
            if (e) {
                return e.id === pokemon.id
            }

            return false
        })
    }

    const haveEmptySlots = () => {
        return pokemonTeam.indexOf(null) > -1
    }

    const sortPokemonList = (a: Pokemon, b: Pokemon) => {
        if (sortStatus === "desc") {
            return b.points - a.points;
        } else if (sortStatus === "asc") {
            return a.points - b.points;
        } else {
            return (a.initialIndex ?? 0) - (b.initialIndex ?? 0);
        }
    };

    useEffect(() => {
        if (pokemonTeam.length > 3) {
            setPokemonTeam(prev => prev.slice(0, 3))
        }
    }, [pokemonTeam])

    return (
        <div className='content-wrapper'>
            {userData.is_admin &&
                <div className="pokemon-admin-tab">
                    {!gameStatus &&
                        <div className="pokemon-admin-control">
                            <p>ASSEGNAMENTO</p>

                            <a onClick={() => updateGameStatus(!lineupEnabled, gameStatus)} >
                                <FontAwesomeIcon icon={lineupEnabled ? faToggleOn : faToggleOff} size='2xl' />
                            </a>

                        </div>
                    }

                    <div className="pokemon-admin-control">
                        <p>IN GAME</p>
                        <a onClick={() => updateGameStatus(lineupEnabled, !gameStatus)} >
                            <FontAwesomeIcon icon={gameStatus ? faToggleOn : faToggleOff} size='2xl' />
                        </a>
                    </div>

                    {/*
                    <div className="pokemon-admin-control">
                        <p>RESET GAME</p>
                        <a onClick={() => resetGame()} >
                            <FontAwesomeIcon icon={faTrashCan} size='xl' />
                        </a>
                    </div>
                    */}
                </div>
            }
            <div className="pokemon">
                <div className="pokedex">
                    <h3 className='pokedex-title'>I miei pokemon</h3>
                    <div className='pokedex-searchbar'>
                        <input ref={searchInput} type="text" onChange={searchPokemon} />
                        {
                            inSearch ?
                                <a onClick={() => {
                                    setMyPokemon(allPokemon)
                                    setInSearch(false)
                                    if (searchInput.current) searchInput.current.value = ""
                                }}>ANNULLA</a>
                                :
                                <a>CERCA</a>
                        }

                        {sortStatus === "none" &&
                            <a onClick={() => setSortStatus("desc")}><FontAwesomeIcon icon={faChevronDown} /></a>
                        }
                        {sortStatus === "desc" &&
                            <a onClick={() => setSortStatus("asc")}><FontAwesomeIcon icon={faChevronUp} /></a>
                        }
                        {sortStatus === "asc" &&
                            <a onClick={() => setSortStatus("none")}><FontAwesomeIcon icon={faClose} /></a>
                        }
                    </div>

                    {pokemonLoading ?
                        <div className="pokedex-list-loading">
                            <CircleLoading color='#fff' />

                        </div>

                        :
                        myPokemon.length === 0 ?
                            <div className="pokedex-list-loading">
                                <h3>Nessun Pokemon disponibile</h3>
                            </div>
                            :
                            <ul className="pokedex-list">
                                {
                                    myPokemon.sort(sortPokemonList).map((el, idx) => {

                                        //let icon: any = JSON.parse(el.api_data)['sprites']['front_default']

                                        return (
                                            <li key={idx}>
                                                <div
                                                    className='pokemon-icon'
                                                    style={{ backgroundImage: `url("${el.image}")` }}
                                                    draggable={!el.died}
                                                    onDragStart={(e: React.DragEvent<HTMLElement>) => {
                                                        if (lineupEnabled) {
                                                            e.dataTransfer.effectAllowed = "move"
                                                            e.dataTransfer.setData("pokemonID", el.id.toString())
                                                            e.currentTarget.className = "pokemon-icon dragged-pokemon"
                                                        }
                                                    }}
                                                ></div>
                                                <p className='pokemon-title'>{el['name']}</p>
                                                <div className='pokemon-type'>
                                                    {
                                                        pokemonTypes(el.types).map((el, idx) => {
                                                            return <PokemonTypeBadge type={el} key={idx} />
                                                        })

                                                    }
                                                </div>
                                                <p className='pokemon-points'>{el['points']} punti</p>

                                               { lineupEnabled && 
                                               <div className="pokedex-item-buttons">
                                                    {
                                                        el.died ?
                                                            <FontAwesomeIcon
                                                                icon={faSkull}
                                                                size='2x'
                                                                color='#fff'
                                                            />
                                                            :
                                                            pokemonIsInTeam(el) ?
                                                                <FontAwesomeIcon
                                                                    icon={faRemove}
                                                                    size='2x'
                                                                    color='red'
                                                                    cursor={"pointer"}
                                                                    onClick={() => removePokemon(el['id'])}
                                                                />
                                                                :
                                                                haveEmptySlots() ?
                                                                    <FontAwesomeIcon
                                                                        icon={faAdd}
                                                                        cursor={"pointer"}
                                                                        size='2x'
                                                                        color='green'
                                                                        onClick={() => addPokemonInTeam(el['id'])}
                                                                    />
                                                                    :
                                                                    null
                                                    }

                                                </div>
                                                }
                                            </li>
                                        )
                                    })
                                }
                            </ul>

                    }
                </div>
                {lineupEnabled &&
                    <div className="user-team">
                        <h3 className='pokemon-team-title'>Team</h3>
                        <div className="pokemon-team">
                            {teamLoaded &&
                                pokemonTeam.map((el: Pokemon | null, idx: number) => {
                                    return (
                                        el !== null ?
                                            (clickedSlot[idx]) ?
                                                <div key={idx} className="pokemon-slot">
                                                    <PokeCircleLoading />
                                                </div>
                                                :

                                                <div
                                                    key={idx}
                                                    onClick={() => { removePokemon(el.id, idx) }}
                                                    className="pokemon-slot"
                                                    style={{ backgroundImage: `url("${el.image}")` }}
                                                ></div>
                                            :
                                            (droppedSlot[idx]) ?
                                                <div key={idx} className="pokemon-slot">
                                                    <PokeCircleLoading />
                                                </div>
                                                :
                                                <div
                                                    key={idx}
                                                    className="pokemon-slot"
                                                    onDragOver={(e: React.DragEvent<HTMLElement>) => {
                                                        e.preventDefault();
                                                        e.dataTransfer.dropEffect = "move"
                                                        e.currentTarget.className = "pokemon-slot pokemon-slot-dragged"
                                                    }}

                                                    onDragLeave={(e: React.DragEvent<HTMLElement>) => {
                                                        e.preventDefault();
                                                        e.currentTarget.className = "pokemon-slot"
                                                    }}

                                                    onDrop={(e: React.DragEvent<HTMLElement>) => {
                                                        e.preventDefault();
                                                        e.currentTarget.className = "pokemon-slot"

                                                        let pokemonID = parseInt(e.dataTransfer.getData("pokemonID"))
                                                        if (teamLoaded && !pokemonTeam.some((el: Pokemon | null) => el === null ? false : el.id === pokemonID)) {
                                                            setDroppedSlot(prev => prev.map((e, i) => i === idx ? !prev[idx] : prev[idx]))
                                                            addPokemonInTeam(pokemonID, idx)
                                                        }

                                                    }}
                                                >
                                                </div>
                                    )
                                })
                            }

                            {!teamLoaded &&
                                pokemonTeam.map((el, idx) => {
                                    return (
                                        <div
                                            key={idx}
                                            className="pokemon-slot"
                                        ></div>
                                    )
                                })
                            }

                        </div>
                    </div>
                }
            </div>
        </div>

    )
}

export default BoxPokemon