import { useEffect, useRef, useState } from 'react'
import { ScratchCard, SCRATCH_TYPE } from 'scratchcard-js'
import '../css/components/Scratchcard.scss'
import scratchcardFront from '../assets/scratchcard.png'
import scratchcardRetro from '../assets/sc_result.png'
import Requests from '../scripts/requests'

interface ScratchcardProps {
    blob: Blob
    callbackFunction: any
}

function Scratchcard({blob, callbackFunction}: ScratchcardProps) {
    const container = useRef<HTMLDivElement>(null);
    const info = useRef<HTMLDivElement>(null);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (container.current && info.current && !loaded) {
            const sc = new ScratchCard(container.current, {
                scratchType: SCRATCH_TYPE.SPRAY,
                containerWidth: 300,
                containerHeight: 300,
                brushSrc: '/images/brush.png', // Assicurati che il percorso sia corretto
                imageForwardSrc: scratchcardFront,
                imageBackgroundSrc: URL.createObjectURL(blob),
                htmlBackground: '',
                percentToFinish: 80,
                enabledPercentUpdate: true,
                clearZoneRadius: 0,
                nPoints: 24,
                pointSize: 50,
                callback: () => callbackFunction()
            });

            // Inizializza ScratchCard e gestisci eventuali errori
            sc.init()
                .then(() => {
                    // Aggiungi evento per aggiornare il progresso di scratch
                    sc.canvas.addEventListener('scratch.move', () => {
                        const percent = sc.getPercent().toFixed(0);
                        if (info.current) {
                            info.current.innerHTML = `${percent}% completato`;
                        }
                    });
                })
                .catch(error => {
                    alert(`Errore nel caricamento: ${error.message}`);
                });

            setLoaded(true);
        }
    }, [loaded]);

    return (
        <div className="sc__wrapper">
            <div ref={container} id="js--sc--container" className="sc__container"></div>
            <div ref={info} className="sc__infos">0% completato</div>
        </div>
    );
}

export default Scratchcard;
