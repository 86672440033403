import React, { useEffect, useState } from 'react'
import '../css/components/LeaderboardFantapoke.scss'
import Requests from '../scripts/requests'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import PokemonScoresOverlay from './PokemonScoresOverlay'
import { useSelector } from 'react-redux'


export interface FantapokeData {
    position: number
    username: string
    display_name: string
    user_id: string
    points: number
}

interface FantapokeDataList{
    [username: string] : FantapokeData
}


function LeaderboardFantapoke() {

    const accessToken = useSelector((state: any) => state.user.accessToken)

    const [leaderboard, setLeaderboard] = useState<FantapokeDataList>({})
    const [search, setSearch] = useState<string>("");

    const [scoresID, setScoresID] = useState<string>("")
    const [scoreUser, setScoreUser] = useState<FantapokeData | null>(null)

    useEffect(() => {
        Requests.getPokemonScores()
            .then(res => {
                console.log(res);                
                setLeaderboard(res)
            })
            .catch(err => null)
    }, [])


    const inputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setSearch(value)
    }

    const goBack = ()=>{
        setScoresID("")
        setScoreUser(null)
    }

    return (
        <div className='fantapoke-leaderboard-wrapper'>
            <img alt='fantapoke-leaderboard_title' src='/img/LEADERBOARD.png' className='fantapoke-leaderboard-title' />
            <div className="fantapoke-search-box">
                <input type="text" className='search-input' onChange={inputChanged} />
                <FontAwesomeIcon icon={faSearch} className='search-icon' />
            </div>

            {scoresID !== "" &&
                <PokemonScoresOverlay
                    access_token={accessToken}
                    user={scoreUser}
                    userID={scoresID}
                    goBack={goBack}
                />
            }

            <ul id="fantapoke-leaderboard">
                {
                    search === "" ?
                        Object.keys(leaderboard).map((username: string, idx: number) => {
                            const e: FantapokeData = leaderboard[username]
                            
                            return <li className='fantapoke-leaderboard-el' key={idx}>
                                <p className='el-username'>{e.position}. {e.display_name}</p>
                                <a className='fantapoke-leaderboard-scores' onClick={() => {
                                    setScoresID(e.user_id)
                                    setScoreUser(e)    
                                }}> apri punteggi</a>
                                <p>{Number.isInteger(e.points) ? e.points : e.points.toFixed(1)} punti</p>
                            </li>
                        })
                        :
                        Object.keys(leaderboard)
                        .filter((e: string) => e.includes(search))
                        .map((username: string, idx: number) => {
                            const e = leaderboard[username]

                            return <li className='fantapoke-leaderboard-el' key={idx}>
                                <p>{e.position}. {e.display_name}</p>
                                <a className='fantapoke-leaderboard-scores' onClick={() => {
                                    setScoresID(e.user_id)
                                    setScoreUser(e)    
                                }}> apri punteggi</a>
                                <span>
                                    <p>{Number.isInteger(e.points) ? e.points : e.points.toFixed(1)} punti</p>
                                </span>
                            </li>
                        })}
            </ul>
        </div>
    )
}

export default LeaderboardFantapoke