import React, { useEffect, useState } from 'react'
import { Product, User } from '../scripts/types'
import { useDispatch, useSelector } from 'react-redux'
import "../css/Shop.scss"
import '../css/components/ShopCard.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faSnowflake, faStar } from '@fortawesome/free-solid-svg-icons'
import ShopOverlay from '../components/ShopOverlay'
import Requests from '../scripts/requests'
import { getItems, setCurrentItem, setTags } from '../redux/features/itemsSlice'
import CircleLoading from '../components/CircleLoading'
import ShopCard from '../components/ShopCard'
import ShopTag from '../components/ShopTag'

/*
const exampleRewards: ShopCard[] = [
  {
    "title": "Cambia voce",
    "description": "Riscatta questa card e fai cambiare la voce a JustSpeetz per 3 minuti",
    "price": 5500
  },
  {
    "title": "Siciliano",
    "description": "Riscatta questa card e fai parlare JustSpeetz in dialetto siciliano per 5 minuti",
    "price": 8500,
    "forSub": true
  },
  {
    "title": "JustCroccantino",
    "description": "Riscatta questa card per far mangiare a JustSpeetz un croccantino di Loki",
    "price": 1350
  },
  {
    "title": "Buono Amazon 10€",
    "description": "Riscatta questa card per ottenere un buono amazon dal valore di 10€",
    "price": 112500
  },
]
*/
/*
interface ShopProps {
  isSubscriber: boolean
}
*/

function Shop() {

  const dispatcher = useDispatch()
  const items: Product[] = useSelector((state: any) => state.items.items)
  const tags: string[] = useSelector((state: any) => state.items.tags)
  const currentItem: Product = useSelector((state: any) => state.items.currentItem)
  const isLoggedIn: boolean = useSelector((state: any) => state.user.loggedIn)
  const userData: User = useSelector((state: any) => state.user.userData)
  //const isSubscriber: boolean = useSelector((state: any) => state.user.subscriber)

  const [dataLoading, setDataLoading] = useState<boolean>(true)
  const [activeTags, setActiveTags] = useState<Array<string>>([])

  useEffect(() => {

    Requests.getAllShopItems()
      .then(res => {
        dispatcher(getItems(res))
      })
      .catch(err => console.log(err))
      .finally(() => setDataLoading(false))

    Requests.getAllShopTags()
      .then(res => {
        dispatcher(setTags(res))
      })
      .catch(err => console.log(err))
  }, [])

  const addRemoveTags = (tag: string) => {
    if (activeTags.includes(tag)) {
      const newArray = activeTags.filter(item => item !== tag);
      setActiveTags(newArray)
    } else {
      let newArray = [...activeTags]
      newArray.push(tag)
      setActiveTags(newArray)
    }
  }

  useEffect(()=>{
    if(!currentItem){
      Requests.getAllShopItems()
        .then(res => {
          dispatcher(getItems(res))
        })
        .catch(err => console.log(err))
    }
    //console.log(currentItem);    
  }, [dispatcher, currentItem])  

  return (
    <div className='content-wrapper'>
      {currentItem &&
        <ShopOverlay
          question={`Sicuro di voler riscattare l'oggetto "${currentItem.title}"?`}
        />
      }


      {dataLoading ?
        <div className="empty-container">
          <CircleLoading color='#fff' />
        </div>

        :

        items.length === 0 ?
          <div className="empty-container">
            <h2 className='shop-empty'>Nessun elemento da visualizzare</h2>
          </div>
          :
          <div className='shop-container'>
            <div id="shop-legend">
              <div id="stock-legend">
                <div id="stock-icon"></div>
                <div id="stock-info">magazzino</div>
              </div>
              <div id="stock-user-legend">
                <div id="stock-user-icon"></div>
                <div id="stock-user-info">limite utente</div>
              </div>
            </div>

            <div className="shop-tags">
              {tags.length > 0 &&
                tags.map((el: string, idx: number) =>
                  el.length > 0 &&
                  <ShopTag
                    title={el}
                    active={activeTags.includes(el)}
                    setTag={addRemoveTags}
                    key={idx}
                  />
                )
              }

              {activeTags.length > 0 &&
                <a className='reset-tags' onClick={() => setActiveTags([])}>
                  <FontAwesomeIcon icon={faClose} color='red' size='2xl' />
                </a>
              }
            </div>
            
            <ShopCardList items={items} activeTags={activeTags} userData={userData} isLoggedIn={isLoggedIn}/>
            
          </div>
      }
    </div>
  )
}

interface ShopCardListProps{
  items: Product[]
  activeTags: string[]
  userData: User
  isLoggedIn: boolean
}

const ShopCardList = ({ items, activeTags, userData, isLoggedIn }: ShopCardListProps) => {
  // Filtrare gli elementi in base ai tag attivi
  const filteredItems:Array<any> = items.filter((el: Product) => {
    if (activeTags.length === 0) return true;
    if (!el.tags) return false;
    const itemTags = el.tags.split(",");
    return itemTags.some((tag: string) => activeTags.includes(tag));
  });

  // Aggiungere elementi vuoti fino a raggiungere almeno 4 elementi
  while (filteredItems.length < 4) {
    filteredItems.push(null);
  }

  return (
    <div className="shop-cards-grid">
      {filteredItems.map((el: Product, idx: number) => {
        if (el) {
          return (
            <ShopCard
              product={el}
              userData={userData}
              isLoggedIn={isLoggedIn}
              key={idx}
            />
          );
        } else {
          return <div className="empty-card" key={idx}></div>;
        }
      })}
    </div>
  );
};


export default Shop